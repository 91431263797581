import { put, call, } from 'redux-saga/effects'
import api from 'services/api'
import NotificationActionCreators from 'ducks/Notifications/actions'
import ActionCreators, { MerchantActionTypes, } from './actions'

export function* fetchMerchantProfile({ merchantId, }) {
  let response

  try {
    response = yield call(api.admin.fetchMerchantProfile, merchantId)

    if (response.status === 200) {
      yield put(ActionCreators.fetchMerchantProfileSuccess(response.data))
    }
  } catch (error) {
    const notification = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.fetchMerchantProfileError({ error, notification, }))
  }
}

export function* fetchFullRatesList() {
  let response

  try {
    response = yield call(api.admin.fetchRatesList)

    if (response.status === 200) {
      yield put(ActionCreators.fetchFullRatesListSuccess(response.data))
    }
  } catch (error) {
    const notify = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.fetchFullRatesListError({ error, notify, }))
  }
}

export function* updateMerchantStatus({ merchantId, merchantStatus, closureReason, }) {
  let response

  try {
    response = yield call(api.admin.updateMerchantStatus, merchantId, merchantStatus, closureReason)

    if (response.status === 200 && merchantStatus !== 'closed') {
      // don't show this for account closures as it has its own confirmation
      yield put(ActionCreators.updateMerchantStatusSuccess())
    } else if (response.status === 200 && merchantStatus === 'closed') {
      yield put(ActionCreators.updateMerchantStatusSuccessNoReload())
    }
  } catch (error) {
    const notification = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.updateMerchantStatusError({ error, notification, }))
    yield put(NotificationActionCreators.showError(notification.title, notification.message))
  }
}

export function* updateFundingHold({ merchantId, data }) {
  let response

  try {
    response = yield call(api.admin.updateFundingHold, merchantId, data)

    if (response.status === 200) {
      yield put(ActionCreators.updateFundingHoldSuccess(response.data))
    }
  } catch (error) {
    const notify = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.updateFundingHoldError({ error, notify, }))
  }
}
export function* revertProcessor({ merchantId, data, }) {
  let response

  try {
    response = yield call(api.admin.revertProcessor, merchantId, data)

    if (response.status === 200) {
      yield put(ActionCreators.revertProcessorSuccess(response.data))
    }
  } catch (error) {
    const notify = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.revertProcessorError({ error, notify, }))
  }
}

export function* acceptTOS({ merchantId, }) {
  let response

  try {
    response = yield call(api.admin.acceptTOS, merchantId)

    if (response.status === 200) {
      yield put(ActionCreators.acceptTOSSuccess(response.data))
    }
  } catch (error) {
    const notify = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.acceptTOSError({ error, notify, }))
  }
}

export function* fetchMerchantAlerts({ merchantId, }) {
  try {
    if (merchantId) {
      const response = yield call(api.admin.fetchMerchantAlerts, merchantId)

      if (response.status === 200) {
        yield put(ActionCreators.fetchMerchantAlertsSuccess(response.data))
      }
    }
  } catch (error) {
    const notify = { level: 'error', title: 'Error', message: error.message, }
    yield put(ActionCreators.fetchMerchantAlertsError({ error, notify, }))
  }
}

export function* sendBeneficialData({ merchantId, data }) {
  let response

  try {
    response = yield call(api.admin.sendBeneficialData, merchantId, data)

    if (response.status === 200) {
      yield put(ActionCreators.sendBeneficialDataSuccess(response))
    }
  } catch (error) {
    let errorMessage = 'An error occurred'
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error
    } else {
      errorMessage = error.message
    }

    const notification = {
      level: 'error',
      title: 'Error',
      message: errorMessage,
    }
    yield put(ActionCreators.sendBeneficialDataError({ message: errorMessage }))
  }
}

export function* fetchMerchantHash({ merchantId }) {
  let response
  try {
    response = yield call(api.admin.merchantHash, merchantId)

    if (response.status === 200) {
      yield put(ActionCreators.fetchMerchantHashSuccess(response))
    }
  } catch (error) {
    let errorMessage = 'An error occurred'
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error
    } else {
      errorMessage = error.message
    }

    const notification = {
      level: 'error',
      title: 'Error',
      message: errorMessage,
    }
    yield put(ActionCreators.fetchMerchantHashError({ message: errorMessage }))
  }
}

export function* uploadCheckoutFile({ file }) {
  try {
    const response = yield call(api.reports.uploadFile, { file, type: 'checkout-images' })

    if (response.status === 200) {
      yield put(ActionCreators.uploadCheckoutFileSuccess(response.data))
    }
  } catch (error) {
    const message = error.response.data && error.response.data.error_message
      ? error.response.data.error_message
      : error.message
    const notify = { level: 'error', title: 'Error', message }
    yield put(ActionCreators.uploadCheckoutFileError({ error, notify, }))
  }
}

export function* removeCheckoutFile({ fileId, fileType }) {
  try {
    const response = yield call(api.reports.removeFile, { fileId })

    if (response.status === 204) {
      yield put(ActionCreators.removeCheckoutFileSuccess({ data: { deleted: true, fileId }, fileType }))
    }
  } catch (error) {
    if (error.response.data.error_message === 'The File specified by the UUID was not found') {
      yield put(ActionCreators.removeCheckoutFileSuccess({ data: { deleted: true, fileId }, fileType }))
    } else {
      const notify = { level: 'error', title: 'Error', message: error.message, }
      yield put(ActionCreators.removeCheckoutFileError({ error, notify, }))
    }
  }
}
