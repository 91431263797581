import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SingleDatePicker, } from 'react-dates'

import 'react-dates/css/styles.scss'
import './DateRangeSelector.scss'

/**
 * Date range selector component.
 * @prop {Moment Date} fromDate: initial "from date".
 * @prop {Moment Date} toDate: initial "to date".
 * @prop {String} placeholder: place holder for the inputs.
 * See defaults props.
 */
class DateRangeSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDateFocus: null,
      toDateFocus: null,
      fromDate: props.fromDate ? props.fromDate : null,
      toDate: props.toDate ? props.toDate : null,
    }
  }

  /**
   * Life cycle method
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const { fromDate, toDate, } = this.props
    if (
      prevProps.fromDate &&
      prevProps.toDate &&
      (
        !moment(prevProps.fromDate).isSame(fromDate, 'day')
        || !moment(prevProps.toDate).isSame(toDate, 'day')
      )
    ) {
      this.setState({
        fromDate,
        toDate,
      }, () => {
        if (this.props.onDateChange) {
          this.props.onDateChange(this.state.fromDate, this.state.toDate)
        }
      })
    }
  }

  /**
   * Builds the icon for the input.
   * @return {Node}
   */
  _buildIcon = () => {
    if (this.props.useMaterialIcon) {
      return <span className='material-icons'>calendar_month</span>
    } else {
      return <span className="ic-calendar"/>
    }
  }

  /**
   * Builds the nav (prev, right) for the calendar.
   * @return {Node}
   */
  _buildNav = (type) => <span className={`ic-nav ic-nav-${type}`} />

  /**
   * On change date callback
   * @param {String} type : fromDate|toDate
   * @param {Object} date : moment date
   */
  _onDateChange = (type, date) => {
    this.setState({
      [type]: date,
    }, () => {
      this.setState({
        fromDate: moment(this.state.fromDate).startOf('day'),
        toDate: moment(this.state.toDate).endOf('day'),
      })

      // If the fromDate selected is greater than the toDate whe reset
      // the toDate to avoid an invalid range.
      if (moment(this.state.fromDate).diff(this.state.toDate, 'days') > 0) {
        this.setState({ toDate: null, })
      }
    })
  }

  /**
   * Public method to retrive the current dates.
   * @return {Object} fromDate & toDate (moment)
   */
  getDates() {
    const fromDate = moment(this.state.fromDate).startOf('day')
    const toDate = moment(this.state.toDate).endOf('day')


    return {
      fromDate,
      toDate,
    }
  }

  render() {
    const { fromDate, toDate, fromDateFocus, toDateFocus, } = this.state
    const { placeholder1, placeholder2, } = this.props

    const error = {
      fromDate: false,
      toDate: false,
    }
    // let error = []
    // if (!fromDate) {
    //   error.push('Please choose a valid date for From input field')
    // }
    // if(!toDate) {
    //   error.push('Please choose a valid date for To input field')
    // }

    if (!fromDate) {
      error.fromDate = 'Please choose a valid date'
    }

    if (!toDate) {
      error.toDate = 'Please choose a valid date'
    }

    return (
      <div className="daterangeselector-component">
        <div className={classnames('picker-container', { 'has-error': !!error.fromDate, })}>
          <span className="label">From:</span>
          <SingleDatePicker
            date={fromDate}
            onDateChange={(date) => { this._onDateChange('fromDate', date) }}
            focused={fromDateFocus}
            onFocusChange={({ focused, }) => this.setState({ fromDateFocus: focused, })}
            hideKeyboardShortcutsPanel
            showDefaultInputIcon
            placeholder={placeholder1}
            customInputIcon={this._buildIcon()}
            navPrev={this._buildNav('prev')}
            navNext={this._buildNav('next')}
            isOutsideRange={(day) => {
              return day.isAfter(moment(), 'day') || (toDate && day.isAfter(toDate, 'day'))
            }}
          />
          {
            error.fromDate ?
              <span className="error">{ error.fromDate }</span> :
              <span className="current-date">{ fromDate ? fromDate.format('ddd, MMM D, YYYY') : '-' }</span>
          }
        </div>
        <div className={classnames('picker-container', { 'has-error': !!error.toDate, })}>
          <span className="label">To:</span>
          <SingleDatePicker
            date={toDate}
            onDateChange={(date) => { this._onDateChange('toDate', date) }}
            focused={toDateFocus}
            onFocusChange={({ focused, }) => this.setState({ toDateFocus: focused, })}
            hideKeyboardShortcutsPanel
            showDefaultInputIcon
            placeholder={placeholder2}
            customInputIcon={this._buildIcon()}
            navPrev={this._buildNav('prev')}
            navNext={this._buildNav('next')}
            isOutsideRange={(day) =>
              day.isBefore(fromDate, 'day') ||
              day.isAfter(moment(), 'day')
            }
          />
          { error.toDate ?
            <span className="error">{ error.toDate }</span> :
            <span className="current-date">{ toDate ? toDate.format('ddd, MMM D, YYYY') : '-' }</span>
          }
        </div>
        {/*
          error.length > 0 &&
          <div className="error-container">
            <span className="error" aria-label={ error.join('\n') }>{error.join('\n')}</span>
          </div>
        */}
      </div>
    )
  }
}

DateRangeSelector.propTypes = {
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
  useMaterialIcon: PropTypes.bool,
}

DateRangeSelector.defaultProps = {
  placeholder1: 'startdate',
  placeholder2: 'enddate',
}


export default DateRangeSelector
