import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Tooltip } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const options = [
  { value: 'one', label: 'One', },
  { value: 'two', label: 'Two', },
]


import 'react-select/scss/default.scss'
import './Selector.scss'

/**
 * Custom select component (with label) Used as main navigation in the app.
 * @prop {String} label: Label for the select
 * @prop {String} placeholder: Placeholder for the select
 * @prop {String/Integer} value: The current or new value of the select.
 * @prop {Array} options : List of objects to show in the select.
 * @prop {String} valueName: Custom key to extract as value from the each object in the options array.
 * @prop {String} labelName: Custom key to extract as label from the each object in the options array
 * See defaults props.
 */
class Selector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value ? props.value : '',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value, })
    }
  }

  /**
   * Build the options for the select.
   */
  _buildOptions = () => {
    const { options, labelName, valueName, } = this.props

    return options.map((opt) => ({
      value: opt[valueName],
      label: opt[labelName],
      disabled: opt.disabled,
      tooltipValues: opt.tooltipValues || []
    }))
  }

  /**
   * On change callback. If a onChange func is passsed as prop,
   * this is called after the state change.
   * @param {Object} option selected.
   */
  _onChange = (option) => {
    this.setState({
      value: option.value,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(option)
      }
    })
  }

  _onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur()
    }
    return null
  }

  _onOpen = () => {
    if (this.props.onOpen) {
      this.props.onOpen()
    }
  }

  _onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  _optionRenderer = (option) => {
    const hasTooltip = option.tooltipValues && option.tooltipValues.length > 0
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{option.label}</span>
        {hasTooltip && (
          <Tooltip
            title={
              <React.Fragment>
                <span>Includes: {option.tooltipValues.join(', ')}</span>
              </React.Fragment>
            }
          >
            <HelpOutlineIcon style={{ fontSize: 18, marginLeft: 4 }} />
          </Tooltip>
        )}
      </div>
    )
  }
  
  _valueRenderer = (option) => {
    const hasTooltip = option.tooltipValues && option.tooltipValues.length > 0
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{option.label}</span>
        {hasTooltip && (
          <Tooltip
            title={
              <React.Fragment>
                <span>Includes: {option.tooltipValues.join(', ')}</span>
              </React.Fragment>
            }
          >
            <HelpOutlineIcon style={{ fontSize: 18, marginLeft: 4 }} />
          </Tooltip>
        )}
      </div>
    )
  }

  render() {
    const { label, loading, placeholder, searchable, disabled, error, helperText, message } = this.props
    const { value, } = this.state

    return (
      <div className="selector-component">
        { label && <span className="label">{ label }</span> }
        <Select
          placeholder={placeholder}
          value={value}
          options={this._buildOptions()}
          clearable={false}
          searchable={searchable}
          disabled={disabled}
          isLoading={loading}
          onBlur={this._onBlur}
          onChange={this._onChange}
          onOpen={this._onOpen}
          onClose={this._onClose}
          optionRenderer={this._optionRenderer}
          valueRenderer={this._valueRenderer}
        />
        { (error && helperText) && <div className="select-error">{ helperText }</div> }
        {message && <div className="select-message">{message}</div>}
      </div>
    )
  }
}

Selector.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value      : PropTypes.string,
  options    : PropTypes.array.isRequired,
  valueName  : PropTypes.string,
  labelName  : PropTypes.string,
  tabIndex   : PropTypes.string,
  message    : PropTypes.string,
}

Selector.defaultProps = {
  label: '',
  valueName: 'value',
  labelName: 'label',
  placeholder: 'Select',
  value: false,
  loading: false,
  message: '',
}

export default Selector
