import { injectReducer, injectSagas, } from 'store'


export default (store) => ({
  path: '/products/:accountId',

  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const ProductReportContainer = require('./containers/ProductReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      /*  Add the reducer to the store on key 'ProductReport'  */
      injectReducer(store, { key: 'productReport', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, ProductReportContainer)

    /* Webpack named bundle   */
    }, 'product-report')
  },
})
